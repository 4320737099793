@mixin fsem($pixels, $context: $browser-context) {
	font-size: #{$pixels/$context}em;
}

// keyframes
@mixin keyframes($name) {
	@-webkit-keyframes #{$name} {
		@content;
	}

	@-moz-keyframes #{$name} {
		@content;
	}

	@-ms-keyframes #{$name} {
		@content;
	}

	@keyframes #{$name} {
		@content;
	}
}

// border radius
@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	-ms-border-radius: $radius;
	border-radius: $radius;
}

// transition
@mixin transition($v1...) {
	-webkit-transition: $v1;
	transition: $v1;
}

// generic transform
@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}

// transform-origin
@mixin transform-origin($val) {
	-moz-transform-origin: $val;
	-o-transform-origin: $val;
	-ms-transform-origin: $val;
	-webkit-transform-origin: $val;
	transform-origin: $val;
}

// animation
@mixin animation($animation, $duration, $delay, $transition, $fill-mode) {
	-webkit-animation-name: $animation;
	-webkit-animation-duration: $duration;
	-webkit-animation-delay: $delay;
	-webkit-animation-timing-function: $transition;
	-webkit-animation-fill-mode: $fill-mode;
	-moz-animation-name: $animation;
	-moz-animation-duration: $duration;
	-moz-animation-delay: $delay;
	-moz-animation-timing-function: $transition;
	-moz-animation-fill-mode: $fill-mode;
	-o-animation-name: $animation;
	-o-animation-duration: $duration;
	-o-animation-delay: $delay;
	-o-animation-timing-function: $transition;
	-o-animation-fill-mode: $fill-mode;
	animation-name: $animation;
	animation-duration: $duration;
	animation-delay: $delay;
	animation-timing-function: $transition;
	animation-fill-mode: $fill-mode;
}

// user-select
@mixin user-select($select) {
	-webkit-user-select: $select;
	-moz-user-select: $select;
	-ms-user-select: $select;
	user-select: $select;
}

// placeholder
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}

// box-shadow
@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	-ms-box-shadow: $shadow;
	box-shadow: $shadow;
}

// selection
@mixin selection {
	&::-moz-selection {
		@content;
	}

	&::-webkit-selection {
		@content;
	}

	&::selection {
		@content;
	}
}

// for :after ps-element
@mixin pseudo($display: block, $pos: absolute, $content: '') {
	content: $content;
	display: $display;
	position: $pos;
}

// for :after ps-element
@mixin clear-before {
	&:before {
		@include pseudo(table, static, ' ');
		@content;
	}
}

@mixin clear-after {
	&:after {
		@include pseudo(table, static, ' ');
		@content;
	}
}

@mixin clear-both {
	@include clear-before;
	@include clear-after;
}

// display: flex
@mixin display-flex {
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
}

@mixin align-items($v) {
	-ms-flex-align: $v;
	-webkit-align-items: $v;
	-webkit-box-align: $v;
	align-items: $v;
}

@mixin justify-content($v) {
	-ms-justify-content: $v;
	-webkit-justify-content: $v;
	justify-content: $v;
}

@mixin flex($v) {
	-webkit-box-flex: $v;
	-ms-flex: $v;
	flex: $v;
}

@mixin letter-spacing($value) {
	letter-spacing: #{$value/1000}em
}

@mixin line-height($value, $textsize) {
	line-height: #{$value/$textsize}
}

// 
@mixin placeholder() {
	&::-webkit-input-placeholder {
		@content;
	}

	&:-moz-placeholder {
		@content;
	}

	&::-moz-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}

	&:-ms-input-placeholder {
		@content;
	}
}

@keyframes banner-in-fade {
	0% {
		transform: translateY(100%);
		transform-origin: left;
		opacity: 0;
	}

	75% {
		transform: translateY(-10%);
	}

	100% {
		transform: translateY(0);
		transform-origin: left;
		opacity: 1;
	}
}

@keyframes banner-out-fade {
	0% {
		transform: translateY(0);
		transform-origin: left;
		opacity: 1;
	}

	80% {
		opacity: 0;
	}

	100% {
		transform: translateY(100%);
		transform-origin: left;
	}
}

@keyframes background-blink {
	from {
		background-color: rgba($secondary-color, 0);

	}
	to{
		background-color: rgba($secondary-color , 1);
	}
}

// End Mixins