// =============================================================================
// String Replace
// =============================================================================
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1)+$replace+str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

// =============================================================================
// Font Face
// =============================================================================
@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;
	$extmods: (eot: "?",
			svg: "#"+ str-replace($name, " ", "_"));
	$formats: (otf: "opentype",
			ttf: "truetype"
		);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src;
		font-display: swap
	}
}

$font-name1: 'LabilGrotesk';
$font-name2: 'TTFors';

@include font-face($font-name1, "../fonts/LabilGrotesk/LabilGrotesk-Light", 300, normal, woff woff2);
@include font-face($font-name1, "../fonts/LabilGrotesk/LabilGrotesk-Regular", 400, normal, woff woff2);

@include font-face($font-name2, "../fonts/TTFors/ttfors-medium", 500, normal, woff woff2);
@include font-face($font-name2, "../fonts/TTFors/ttfors-semibold", 600, normal, woff woff2);
@include font-face($font-name2, "../fonts/TTFors/ttfors-bold", 700, normal, woff woff2);
@include font-face($font-name2, "../fonts/TTFors/ttfors-extrabold", 800, normal, woff woff2);
