/*!
Theme Name: Space The 360
Author: Tooltyp Media
Author URI: https://tooltyp.com
Description: Tema creado para Space The 360
Version: 1.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: Space The 360
*/

@import "../../../node_modules/bootstrap/scss/bootstrap";

@import "normalize";
@import 'fonts';
@import 'variables';
@import 'mixins';


@import 'content';

//@import 'tt-scrollreveal';
