$primary-font:   $font-name1, sans-serif;
$secondary-font: $font-name2, sans-serif;


$white-color: 		#FFFFFF;
$black-color: 		#001225;

$primary-color:		#C3FF18;
$secondary-color:	#001225; 
$terciary-color:	#00F0FF;

$secondary-colo-light: rgba(24, 58, 85, 0.08); 

$disabled-color: #ffffb4;

$fw-thin: 		100;
$fw-extralight: 200;
$fw-light: 		300;
$fw-regular: 	400;
$fw-medium: 	500;
$fw-semibold: 	600;
$fw-bold: 		700;
$fw-black: 		800;

$fw-size: 15px;
$line-height: 23px;

$template-width: 1530px;
$bs-gutter-x: 120px;


:root {
	--primary-font: $font-name1, sans-serif;
	--bs-gutter-x: 44px;
	touch-action: pan-x pan-y;
	height: 100%;

	@include media-breakpoint-up(lg) {
		--bs-gutter-x: 120px;
	}
	@include media-breakpoint-down(lg) {
		--bs-gutter-x: 25px;
	}

	--padding-blocks: 60px;

}